import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { CheckSquare, X, Menu } from 'lucide-react';
import axios from 'axios';
function FadeInSection({ children }) {
  const domRef = useRef();
  
  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('is-visible');
        }
      });
    });
    
    observer.observe(domRef.current);
    
    return () => observer.unobserve(domRef.current);
  }, []);
  
  return (
    <div ref={domRef} className="fade-in-section">
      {children}
    </div>
  );
}

function App() {
  const scrollToSection = (event, sectionId) => {
    event.preventDefault();
    const section = document.getElementById(sectionId);
    const navbarHeight = 80; // Approximate height of the navbar
    const sectionPosition = section.getBoundingClientRect().top;
    const offsetPosition = sectionPosition + window.pageYOffset - navbarHeight;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    });
  };

  const [activeService, setActiveService] = useState(null);
  const [hasAnimated, setHasAnimated] = useState(false);
  const [businessName, setBusinessName] = useState('');
  const [email, setEmail] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const serviceInfo = {
    Research: "Our research team dives deep into data specifically catered to your campaign/creative direction to uncover insights that drive your strategy.",
    Consulting: "It's more than just data. We'll work with you to understand your goals and provide tailored recommendations.",
    Analytics: "We will do further data/trend analysis behind the scenes to provide you with reports and infographics so you can see the big picture."
  };

  const toggleService = (service) => {
    if (!activeService) {
      setHasAnimated(true);
    }
    setActiveService(activeService === service ? null : service);
  };

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  useEffect(() => {
    setIsFormValid(businessName.trim() !== '' && validateEmail(email));
  }, [businessName, email]);

  const handleSignUp = async (e) => {
    e.preventDefault();
    if (isFormValid) {
      try {
        const response = await axios.post('/api/signup', { businessName, email });
        console.log('Signup successful:', response.data);
        // Clear form fields and show success message
        setBusinessName('');
        setEmail('');
        alert('Thank you for signing up!');
      } catch (error) {
        console.error('Error signing up:', error);
        alert('An error occurred. Please try again.');
      }
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="font-sans text-gray-800 bg-white min-h-screen">
        <Helmet>
        <title>Datawham - Affordable Market Intelligence Data</title>
        <meta name="description" content="Empower your creative vision with cross-platform trend insights at a fraction of the cost." />
        <meta name="keywords" content="datawham, affordable market intellgience, market intelligence, data analytics, trend insights, small agency, social media trends, news trends, Google search trends" />
        <link rel="canonical" href="https://www.datawham.com" />
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
      </Helmet>
      <header className="fixed top-2 md:top-4 left-0 right-0 z-50 flex justify-center">
        <div className="w-[95%] md:w-[90%] lg:max-w-[75%] bg-white rounded-full shadow-[0_4px_12px_rgba(0,0,0,0.1)] flex justify-between items-center p-2 md:p-4">
          <div 
            className="font-bold cursor-pointer text-base md:text-xl lg:text-2xl flex items-center" 
            onClick={(e) => scrollToSection(e, 'home')}
          >
            <img src="/datawhamnew.svg" alt="Datawham Logo" className="h-7 md:h-11 mr-2" />
            Datawham
          </div>
          <div className="md:hidden">
            <button onClick={toggleMenu} className="focus:outline-none p-1">
              <Menu size={20} />
            </button>
          </div>
          <nav className={`${isMenuOpen ? 'flex' : 'hidden'} md:flex absolute md:static top-full left-0 right-0 bg-white md:bg-transparent flex-col md:flex-row items-center space-y-2 md:space-y-0 md:space-x-4 p-4 md:p-0 mt-2 md:mt-0 rounded-b-lg shadow-md md:shadow-none transition-all duration-300 ease-in-out w-[95%] md:w-auto mx-auto md:mx-0`}>
            <a href="#explore" onClick={(e) => scrollToSection(e, 'explore')} className="text-sm md:text-base text-gray-800 hover:text-gray-600">Explore</a>
            <a href="#pricing" onClick={(e) => scrollToSection(e, 'pricing')} className="text-sm md:text-base text-gray-800 hover:text-gray-600">Pricing</a>
            <a href="#signup" onClick={(e) => scrollToSection(e, 'signup')} className="bg-[#FF999A] text-white px-4 md:px-5 py-1 rounded-full text-sm md:text-base font-semibold shadow-md hover:bg-[#FF8A8B] transition duration-300 transform hover:scale-110">Sign Up</a>
          </nav>
        </div>
      </header>

      <main>
        {/* First screen content */}
        <section id="home" className="min-h-[70vh] md:h-screen-[98vh] flex flex-col justify-center items-center text-center px-4 pt-20 md:pt-0 md:mt-8">
          <div className="flex justify-center space-x-6 mb-6">
            <div className="flex items-center">
              <CheckSquare className="mr-1" size={22} />
              <span className="text-sm">Social Media</span>
            </div>
            <div className="flex items-center">
              <CheckSquare className="mr-1" size={22} />
              <span className="text-sm">News Publishers</span>
            </div>
            <div className="flex items-center">
              <CheckSquare className="mr-1" size={22} />
              <span className="text-sm">Google Search</span>
            </div>
          </div>
          <h1 className="text-5xl md:text-5xl lg:text-6xl font-bold mb-4">
            Small agency?<br className="md:hidden" /> Big data.
          </h1>
          <p className="text-lg sm:text-2xl mb-6">
            Empower your creative vision with cross-platform trend insights
            <br />
            (at a fraction of the cost)
          </p>
          <button 
            onClick={(e) => scrollToSection(e, 'signup')}
            className="bg-[#FF999A] text-white px-5 font-semibold py-2 rounded-full shadow-md hover:bg-[#FF8A8B] transition duration-300 mb-16 transform hover:scale-110"
          >
            Join Waitlist
          </button>
        </section>

        {/* Image container */}
        <FadeInSection>
          <div className="w-full flex justify-center items-center mt-8 md:mt-0">
            <div className="w-[91%] md:w-[75%] relative overflow-hidden rounded-2xl border-2 border-black shadow-xl flex items-center justify-center bg-white">
              <img 
                src="/GraphPic.png" 
                alt="Top trends graph" 
                className="w-full h-auto object-contain"
              />
            </div>
          </div>
        </FadeInSection>

        {/* Second screen content */}
        <FadeInSection>
          <section id="explore" className="py-12 md:min-h-[55vh] md:pt-20 flex flex-col justify-start items-center">
            <div className="max-w-4xl mx-auto text-center">
              <h2 className="text-4xl sm:text-4.5xl font-bold mb-5">Explore the top trends of 2024</h2>
              <p className="text-lg sm:text-2xl mb-3">Linkriff, our proprietary intelligence software,<br className="md:hidden" /> lets you analyze trends word by word.</p>
              <ul className="text-lg sm:text-2xl list-disc space-y-1 inline-block text-center" style={{ listStylePosition: 'inside' }}>
                <li>Verified cross-platform sources</li>
                <li>Trend insights from news, social media, and search</li>
                <li>Day specific trend data</li>
              </ul>
            </div>
          </section>
        </FadeInSection>

        {/* Section with image on left, text on right */}
        <FadeInSection>
          <section className="py-12 md:min-h-[50vh] md:py-16 px-4 flex flex-col justify-center items-center">
            <div className="max-w-4xl mx-auto mb-16 flex flex-col md:flex-row items-center">
              <img src="/ConnectPic.png" alt="Topic relationships graph" className="w-full md:w-3/5 rounded-2xl shadow-xl border-2 border-black mb-8 md:mb-0" />
              <div className="md:w-1/2 text-center md:text-left md:pl-12">
                <h2 className="text-4xl font-bold mb-4">Understand <br/> topic relationships</h2>
                <p className="text-lg sm:text-2xl mb-4">Our unique keyword connection algorithm lets you see a holistic view of how viral trends are connected.</p>
              </div>
            </div>
          </section>
        </FadeInSection>

        {/* Section with text on left, image on right */}
        <FadeInSection>
          <section className="py-12 md:min-h-[65vh] md:py-16 px-4 flex flex-col justify-center items-center">
            <div className="max-w-4xl mx-auto flex flex-col-reverse md:flex-row items-center">
              <div className="md:w-1/2 text-center md:text-left md:pr-12 mt-8 md:mt-0">
                <h2 className="text-4xl font-bold mb-4">Discover campaign <br/> direction with AI</h2>
                <p className="text-lg sm:text-2xl mb-4">Interact with trend data using our specialized AI:</p>
                <ul className="text-lg sm:text-2xl space-y-2 list-disc pl-0 md:pl-5 inline-block text-center md:text-left">
                  <li>Pattern Detection</li>
                  <li>Context Inference</li>
                  <li>Strategy Discussion</li>
                </ul>
              </div>
              <img src="/AiPic.png" alt="AI pattern detection graph" className="w-full md:w-3/5 rounded-2xl shadow-xl border-2 border-black" />
            </div>
          </section>
        </FadeInSection>

        <FadeInSection>
          <section id="pricing" className="py-12 md:min-h-[70vh] md:py-16 px-4 flex flex-col justify-center items-center text-center">
            <h2 className="text-4xl font-bold mb-4">Expensive data at a cheap price</h2>
            <p className="text-lg sm:text-2xl mb-8">
              <span className="text-xl sm:text-2xl font-bold">Save</span> up to <span className="text-xl sm:text-2xl font-bold">95%</span> compared to similar market intelligence competitors.
            </p>
            <div className="flex flex-col md:flex-row justify-center space-y-8 md:space-y-0 md:space-x-8">
              <div className="bg-white p-6 rounded-lg shadow-md">
                <h3 className="text-2xl font-bold mb-4">Us</h3>
                <div className="flex justify-center items-center mb-4">
                  <img src="/datawhamnew.svg" alt="Datawham Logo" className="h-9 w-auto" />
                </div>
                <ul className="list-disc text-left pl-5 space-y-2 mb-4">
                  <li>Project based pricing</li>
                  <li>Full transparency</li>
                  <li>Custom tailored <br/> service package</li>
                </ul>
                <div className="flex justify-center items-center text-green-500">
                  <CheckSquare size={24} />
                </div>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-md">
                <h3 className="text-2xl font-bold mb-2">Them</h3>
                <div className="flex justify-center items-center mb-4 space-x-4">
                  <img src="/Newswhiplogo.png" alt="Competitor 1" className="h-8 w-auto" />
                  <img src="/brandwatch.svg" alt="Competitor 3" className="h-6 w-auto" />
                  <img src="/meltwater.svg" alt="Competitor 2" className="h-8 w-14" />
                </div>
                <ul className="list-disc text-left pl-5 space-y-2 mb-4">
                  <li>Subscription based</li>
                  <li>Hidden limits/fees</li>
                  <li>Upfront yearly rate  <br/> range of $5k - $20k+</li>
                </ul>
                <div className="flex justify-center items-center text-red-500">
                  <X size={24} />
                </div>
              </div>
            </div>
          </section>
        </FadeInSection>

        <FadeInSection>
          <section className="py-12 md:min-h-[55vh] md:py-16 px-4 flex flex-col justify-center items-center text-center">
            <h2 className="text-4xl font-bold mb-4">Plus...</h2>
            <h3 className="text-4xl font-bold mb-6">We'll do the work <span className="italic">WITH</span>  you.</h3>
            <p className="text-lg sm:text-2xl mb-8">
              Tell us your vision and our team will help get the data you need
            </p>
            <div className="flex flex-wrap justify-center gap-4 mb-8">
              {Object.keys(serviceInfo).map((service) => (
                <button
                  key={service}
                  onClick={() => toggleService(service)}
                  className={`px-6 py-1 rounded-full text-lg transition-all duration-300 ${
                    activeService === service
                      ? 'bg-white text-black border-2 border-black'
                      : 'bg-black text-white hover:bg-gray-800'
                  }`}
                >
                  {service}
                </button>
              ))}
            </div>
            <div className="max-w-md w-full">
              {activeService && (
                <div className={`bg-white border-2 border-black rounded-lg p-4 shadow-lg ${!hasAnimated ? 'animate-fade-in' : ''}`}>
                  <p>{serviceInfo[activeService]}</p>
                </div>
              )}
            </div>
          </section>
        </FadeInSection>

        <FadeInSection>
          <section id="signup" className="py-12 min-h-screen md:py-16 px-4 flex flex-col justify-center items-center text-center">
            <h2 className="text-5xl font-bold mb-4">Be the first to know.</h2>
            <p className="text-lg sm:text-2xl mb-8">Sign up for the waitlist to know when our beta goes live.</p>
            <form onSubmit={handleSignUp} className="w-full max-w-md">
              <div className="mb-6">
                <input
                  type="text"
                  placeholder="Business Name"
                  value={businessName}
                  onChange={(e) => setBusinessName(e.target.value)}
                  className="w-full mb-4 px-4 py-2 border-b-2 border-gray-300 focus:border-pink-300 outline-none transition-colors"
                  required
                />
                <input
                  type="email"
                  placeholder="Email Address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full px-4 py-2 border-b-2 border-gray-300 focus:border-pink-300 outline-none transition-colors"
                  required
                />
              </div>
              <button 
                type="submit"
                disabled={!isFormValid}
                className={`bg-[#FF999A] text-white px-8 py-2 rounded-full font-semibold shadow-md transition duration-300 transform hover:scale-110 ${
                  isFormValid ? 'hover:bg-[#FF8A8B]' : 'opacity-50 cursor-not-allowed'
                }`}
              >
                Sign Up
              </button>
            </form>
          </section>
        </FadeInSection>
      </main>
      <footer>
        <p>&copy; 2024 Datawham.</p>
      </footer>
    </div>
  );
}

export default App;